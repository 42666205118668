<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Principal Message</h2>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- courses start -->
<section class="irs-welcome-field">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-sm-2">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/principal.jpeg" alt="Nursing College Principal">
                </div>
            </div>
            <div class="col-md-8 col-md-offset-1 text-center">
                <!-- <h3 class="irs-sidebar-title"><span>Principal</span> Message</h3> -->
                <p class="text-left">Namaste!
                </p>
                <p class="text-justify">
                    As the principal of <strong>Shree Umiya K.V.C Nursing College</strong>, For Girls, it is my pleasure to welcome you
                    to our college. The college has a rich history of educating exceptional nurses who are making a
                    difference in the health care of individuals in our region and beyond. The college of nursing is
                    poised to address the challenges with innovative academic programs that facilitate students’
                    progression toward advanced academic degrees.</p>
                <p class="text-justify">
                    We are focused on student’s success. You will discover dedicated personals who are devoted to
                    academic perfection and in providing a quality learning environment for students.</p>
                <p class="text-justify">
                    Latest technology educational aids are used to make teaching learning experience more interesting
                    and wholesome. The college has well-equipped labs. Library of college is well stocked latest edition
                    books, national and international journals. The college possesses good and comfortable accommodation
                    for girls with all facilities including round the clock security arrangements.</p>
                <p class="text-justify">
                    The college also has its own fleet of buses for transportation of students. The college offers
                    <strong>General Nursing and Midwifery(G.N.M), B.Sc Nursing</strong>, courses. The Institute pledges to strive hard to
                    become a center of excellence in nursing education.
                    Thank you for stopping by our website.
                </p>
                <p class="text-justify"> <strong>MS Kruti Contractor</strong>
                    Principal,
                    (M.Sc nursing ,MSN)
                </p>

            </div>
        </div>
    </div>
</section>
<!-- courses end -->