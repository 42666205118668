import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-presidentmessage',
  templateUrl: './presidentmessage.component.html',
  styleUrls: ['./presidentmessage.component.scss']
})
export class PresidentmessageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
