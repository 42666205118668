import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './component/contact/contact.component';
import { HomeComponent } from './component/home/home.component';
import { PresidentmessageComponent } from './component/presidentmessage/presidentmessage.component';
import { PrincipalmessageComponent } from './component/principalmessage/principalmessage.component';
import { VisionmissionComponent } from './component/visionmission/visionmission.component';
import { CourseComponent } from './component/course/course.component';
import { AdmissiontermComponent } from './component/admissionterm/admissionterm.component';
import { InfrastructureComponent } from './component/infrastructure/infrastructure.component';
import { JobopportunityComponent } from './component/jobopportunity/jobopportunity.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { TesturlComponent } from './component/testurl/testurl.component';



const routes: Routes = [
  {
    // path: 'home',
    // loadChildren: () =>
    //   import('./component/home/home.component').then(m => m.HomeComponent)
    path: '', component: HomeComponent
  },
  {
    path: 'home', component: HomeComponent
  },
  {
    path: 'contact', component: ContactComponent
  },
  {
    path: 'presidentmessage', component: PresidentmessageComponent
  },
  {
    path: 'principalmessage', component: PrincipalmessageComponent
  },
  {
    path: 'visionmission', component: VisionmissionComponent
  },
  {
    path: 'course', component: CourseComponent
  },
  {
    path: 'admissionterm', component: AdmissiontermComponent
  },
  {
    path: 'jobopportunity', component: JobopportunityComponent
  },
  {
    path: 'infrastructure', component: InfrastructureComponent
  },
  {
    path: 'gallery', component: GalleryComponent
  },
  {
    path: 'testurl', component: TesturlComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
