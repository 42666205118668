<!-- main slider start -->
<section class="irs-main-slider">
    <div class="carousel fade-carousel slide" data-ride="carousel" data-interval="4000" id="bs-carousel">
        <!-- Indicators -->
        <ol class="carousel-indicators">
            <li data-target="#bs-carousel" data-slide-to="0" class="active"></li>
            <li data-target="#bs-carousel" data-slide-to="1"></li>
            <li data-target="#bs-carousel" data-slide-to="2"></li>
        </ol>
        <!-- Wrapper for slides -->
        <div class="carousel-inner">
            <div class="item slides active">
                <div class="slide-1"></div>
                <div class="hero">
                    <span>learn and build your life</span>
                    <h1>Nursing Courses for your<strong> bright future</strong></h1>
                    <!-- <a href="#" class="btn btn-default irs-big-btn" role="button">Register</a> -->
                </div>
            </div>
            <div class="item slides">
                <div class="slide-2"></div>
                <div class="hero">
                    <!-- <span>learn and build your life</span> -->
                    <!-- <h1>gain knowledge from <strong>education park</strong></h1> -->
                    <!-- <a href="#" class="btn btn-default irs-big-btn" role="button">Register</a> -->
                </div>
            </div>
            <div class="item slides">
                <div class="slide-3"></div>
                <div class="hero">
                    <!-- <span>Lamp lighting &</span> -->
                    <h1>lamp lighting & <strong>OATH taking ceremony</strong></h1>
                    <!-- <a href="#" class="btn btn-default irs-big-btn" role="button">Register</a> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Main slider end -->

<!-- Welcome start -->
<section class="irs-welcome-field">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
                <h1>Welcome To</h1>
                <h2>Shree Umiya K.V.C. <br><span>Nursing College</span> <br>For Girls</h2>
                <i class="icofont icofont-education"></i>
                <p class="text-justify"><strong>Shree Umiya K.V.C Nursing College</strong>, For Girls is one of the most
                    modern equipped nursing colleges in Ahmedabad, Gujarat. Our college managed by <strong>Shree Umiya
                        K.V.C Education Trust</strong>. It
                    is popularly sighted as a center of excellence in the field of nursing education with excellence in
                    job training facilities in East Zone. The institution has a magnificent manifold of modern buildings
                    and is equipped with all kinds of modern facilities for providing best knowledge and training to the
                    undergraduate students. The Institution has come up by dint of hard work and sincere efforts of
                    management staff, employees and also the cooperation of students.
                </p>
                <p class="text-justify">
                    This grew in the form of <strong>Shree Umiya K.V.C Nursing College</strong>, For Girls in 2019 with
                    3 years General Nursing & Midwifery(G.N.M), Diploma course and 4 Years B.Sc. Nursing degree course.
                    Nursing is a profession within the Health Care Sector focused on the care of individuals, families
                    and communities so they may attain maintain or recover optimal health and quality of life.
                </p>
                <p class="text-justify">
                    Nurses care for individuals of all ages and all cultural backgrounds who are healthy and ill in a
                    holistic manner based on the individuals’ physical, emotional, psychological, intellectual, social
                    and spiritual needs, the profession combines physical sciences, social science and technology in
                    caring for those individuals.
                </p>
                <p class="text-justify">
                    We are Affiliated with Gujarat University Ahmedabad, Gujarat Nursing Council Ahmadabad, Indian
                    Nursing Council, New Delhi. Our graduates are sought after as highly skilled professionals who have
                    received a rigorous education of the highest quality.
                </p>
            </div>
        </div>

    </div>
</section>
<!-- Welcome end -->
<!-- blog single start -->
<section class="irs-blog-single-field">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="irs-comment-single irs-blog-single-col">
                    <div class="irs-comment-item">
                        <img src="../../../assets/images/blog/icon-1.png" alt="">
                        <div class="irs-comment-content">
                            <h4><a href="#">Lecture Hall</a> </h4>
                            <p>(100 seating capacity) : All the  Lecturer rooms are well equip with teaching learning facility like LCD, screen , green board, white board, podium and comfortable bench for the 60 students.</p>
                        </div>
                    </div>
                    <div class="irs-comment-item irs-comment-item-right">
                        <img src="../../../assets/images/blog/icon-5.png" alt="">
                        <div class="irs-comment-content">
                            <h4><a href="#">Multipurpose Hall</a> </h4>
                            <p>Multipurpose hall is utilised for hosting function of college education conferences/workshops, CNES examinations etc. In hall have a proper stage with green room facility. The hall is well ventilated with proper lighting facilities available.  The proper arrangements of place for the use of all kind of basic and advance audiovisual aids are available in hall.</p>
                        </div>
                    </div>
                    <div class="irs-comment-item">
                        <img src="../../../assets/images/blog/icon-3.png" alt="">
                        <div class="irs-comment-content">
                            <h4><a href="#">Library</a> </h4>
                            <p>The library of our college has proper ventilation and proper lighting. The cabin of librarian with intercom phone facility and with internet available.  The comfortable seating arrangement for students and teachers in our library. The separated reading room also there in library. Book display racks at bulletin boards current books, magazines, journals, newspapers and other literature available in our library</p>
                        </div>
                    </div>
                    <div class="irs-comment-item irs-comment-item-right">
                        <img src="../../../assets/images/blog/icon-2.png" alt="">
                        <div class="irs-comment-content">
                            <h4><a href="#">Laboretris</a> </h4>
                            <p>All Seven Labs are Well Equipped with Washing Facilities and Articles, Instruments, Dummies, Skeleton, Charts, Models, Bones, Torso and Community Bags.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>