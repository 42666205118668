<div class="preloader"></div>
<!-- Main Header Start -->
<header class="irs-main-header">
    <div class="irs-header-top-bar">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <div class="irs-header-top-col title-text text-center ">
                        <p> "Shree Umiya Education Foundation For Girls"
                        </p>
                    </div>
                </div>

                <!-- <div class="col-md-3 col-md-offset-5 col-sm-4">
                        <div class="irs-header-top-col text-right irs-center-2">
                            <div class="irs-social text-right irs-center-2">
                                <a href="#"><i class="icofont icofont-social-facebook"></i></a>
                                <a href="#"><i class="icofont icofont-social-twitter"></i></a>
                                <a href="#"><i class="icofont icofont-social-vimeo"></i></a>
                                <a href="#"><i class="icofont icofont-brand-linkedin"></i></a>
                            </div>
                        </div>
                    </div> -->
            </div>
        </div>
    </div>
    <div class="irs-header-nav scrollingto-fixed">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12">
                    <nav class="navbar navbar-default irs-navbar">
                        <div class="container-fluid">
                            <!-- Brand and toggle get grouped for better mobile display -->
                            <div class="navbar-header">
                                <button type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                                    <span class="sr-only">Toggle navigation</span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                    <span class="icon-bar"></span>
                                </button>
                                <div class="navbar-brand" routerLink="/home">
                                    <img src="../../../assets/images/logo.png" alt="Umiya Nursing College">
                                </div>
                                <div class="navbar-brand">
                                    <h2>shree umiya k.v.c. <br><span><strong>nursing college</strong></span></h2>
                                </div>

                            </div>
                            <!-- Collect the nav links, forms, and other content for toggling -->
                            <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul class="nav navbar-nav navbar-right" data-hover="dropdown" data-animations="flipInY">
                                    <li class="dropdown">
                                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">About <i
                                                class="fa fa-angle-down"></i></a>
                                        <ul class="dropdown-menu">
                                            <li><a routerLink="/presidentmessage">President's Message</a>
                                            </li>
                                            <li><a routerLink="/principalmessage">Principal's Message</a>
                                            </li>
                                            <li>
                                                <a routerLink="/visionmission">Vision & Mission</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li class="dropdown">
                                        <a href="#" class="dropdown-toggle" data-toggle="dropdown" role="button"
                                            aria-haspopup="true" aria-expanded="false">Admission <i
                                                class="fa fa-angle-down"></i></a>
                                        <ul class="dropdown-menu">
                                            <li><a routerLink="/course">Our Courses</a>
                                            </li>
                                            <li><a routerLink="/admissionterm">Admission Terms</a>
                                            </li>
                                        </ul>
                                    </li>
                                    <li><a routerLink="/infrastructure">Physical Facility</a></li>
                                    <li><a routerLink="/jobopportunity">Job Opportunities</a></li>
                                </ul>
                            </div>
                            <!-- /.navbar-collapse -->
                        </div>
                        <!-- /.container-fluid -->
                    </nav>
                </div>
            </div>
        </div>
    </div>
    
</header>
<!-- Main Header end -->