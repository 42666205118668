<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>President Message</h2>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- courses start -->
<section class="irs-welcome-field">
    <div class="container">
        <div class="row">
            <div class="col-md-2 col-sm-2">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/president.jpg" alt="Umiya K.V.C. Trust President">
                </div>
            </div>
            <div class="col-md-8 col-md-offset-1 text-center">
                <!-- <h3 class="irs-sidebar-title"><span>President</span> Message</h3> -->
                <p class="text-left">Namaste!
                </p>
                <p class="text-justify">
                    The population of Gujarat is agriculture oriented and is divided into small villages. Millions
                    of people are living in villages where the higher education facilities are hardly available. Lack
                    of higher education, minimum transport facilities and lack of basic amenities are part of their
                    life. Because of lack of higher education facilities, they are ready to send their sons in cities.
                    But
                    they hesitate to send their daughters for further study. But in the words of Mahatma Gandhi
                    “Lack of education in women hampers the all round development of the society.”
                </p>
                <p class="text-justify">In such circumstances, a trustworthy higher educational institute for girls
                    is utmost required.
                    To fulfill this purpose, the leading people of patidar community gathered and established a
                    trust
                    entitled Shree Khakhariya, Vadhiyar and Chuval Education Trust <strong>[Shree K.V.C. Education Trust]</strong> for
                    the
                    upliftment and development of girls from Khakhariya, Vadhiyar and Chuval regions of Gujarat with
                    hostel facilities in 1995.
                </p>
                <p class="text-justify"> Equal partnership of men & women is required in productive and reproductive
                    life which includes
                    shared responsibilities for nurturing future generation. Girls play vital role in developing
                    society
                    as a powerful woman is capable of taking proper decisions. By imparting higher education, the
                    community leaders want to establish women empowerment through they we can enhance over all
                    education
                    empowerment, awareness and literary level which lead to sustainable development of the
                    community.
                    In 2019, <strong>Shree Umiya K.V.C Nursing college</strong> ,For Girls was established and has taken up the
                    initiative for the growth in Nursing Profession by imparting higher Education to girls. Ours is
                    one
                    of the most leading Pera Medical educational institutions for higher study in these regions
                    ,Gujarat. It has made progress and has been achieving new milestones year after year. In order
                    to
                    fulfill the standards of excellence, the college has been trying its level best to improve the
                    quality and content of education. In every discipline, we strive to keep pace with the progress
                    of
                    knowledge.
                </p>
                <p class="text-justify"> The college has been actively involved in inspiring diversified interests
                    among the students to make
                    them alert and dynamic. I am sure, it will shine out as an institution par excellence in future.
                    Our nursing college affiliated by Indian Nursing Council,Gujarat Nursing Council, & Gujarat
                    university. I congratulate Prin. Ms Kruti B. Contractor and entire team of college who have
                    worked
                    very systematically and very hard by putting since most efforts . I wish to all for efficient
                    and
                    scholarly team a grand success for the same.
                </p>
                <p class="text-justify"> <strong>Shree Jayantibhai M. Patel</strong>

                </p>

            </div>
        </div>
    </div>
</section>
<!-- courses end -->