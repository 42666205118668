<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Contact Us</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- Contact start -->
<section class="irs-contact-field">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="google-map irs-google-map" id="map-canvas" style="height: 450px;">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d917.5473849224895!2d72.52676725989174!3d23.09015641644559!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e833190f2e5ed%3A0x1f2fdf3b359c7559!2sShree%20Umiya%20K.V.C.%20Nursing%20College!5e0!3m2!1sen!2sin!4v1626517379855!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 col-sm-6">
                <div class="irs-contact-col">
                    <div class="row">
                        <form id="contact_form" [formGroup]="FormData" (ngSubmit)="onSubmit()">
                            <div class="col-md-6">
                                <input name="Name" formControlName="Name" class="form-control" placeholder="Name*" required="required" data-error="Name is required." type="text">
                            </div>
                            <div class="col-md-6">
                                <input name="Email" formControlName="Email" class="form-control" aria-describedby="emailHelp" placeholder="Email*" required="required" data-error="Email is required." type="text">
                                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                            </div>
                            <div class="col-md-12">
                                <input name="Mobile" formControlName="Mobile" class="form-control" placeholder="Mobile No*" pattern="^\d{10}$" required="required" data-error="Mobile is required." type="text">
                            </div>
                            <div class="col-md-12">
                                <textarea name="Message" formControlName="Message" class="form-control irs-textarea" rows="12" placeholder="Comment*" required="required" data-error="Message is required."></textarea>
                                <button class="btn btn-default irs-big-btn" type="submit">send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-address-col">
                    <i class="icofont icofont-telephone"></i>
                    <p>91- 79-29609664</p>
                </div>
                <div class="irs-address-col">
                    <i class="icofont icofont-email"></i>
                    <p>shreeumiyakvcnursingcollege@gmail.com</p>
                </div>
                <div class="irs-address-col">
                    <i class="icofont icofont-social-google-map"></i>
                    <p>Umiya Campus,
                        Nr. Bhagvat Vidyapith,
                        Sarkhej-Gandhinagar Highway,
                        Sola, Ahmedabad-380060</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact end -->