<!-- Footer start -->
<footer class="irs-footer-field">
    <div class="container">
        <div class="row animatedParent animateOnce animateOnce">
            <div class="col-md-7">
                <h4 class="irs-footer-heading text-center"><br>Shree Umiya K.V.C. <span
                        class="title-orange"><strong>Nursing
                            College</strong></span></h4>
                <div class=" col-md-5 irs-footer-about">

                    <div class="irs-mailbox">
                        <p><i class="zmdi zmdi-gps-dot"></i> Umiya Campus,<br>Nr. Bhagvat
                            Vidyapith,<br>Sarkhej-Gandhinagar Highway,<br>Sola, Ahmedabad-380060</p>
                    </div>
                    <div class="irs-social">
                        <a href="https://www.facebook.com/umiyakvcnursingcollege" target="_blank"><i class="icofont icofont-social-facebook"></i></a>
                        <!-- <a href="#"><i class="icofont icofont-social-twitter"></i></a>
                        <a href="#"><i class="icofont icofont-social-vimeo"></i></a>
                        <a href="#"><i class="icofont icofont-brand-linkedin"></i></a> -->
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="irs-footer-contact">
                        <div class="irs-mailbox">
                            <p><i class="zmdi zmdi-email"></i> Mail: shreeumiyakvcnursingcollege@gmail.com </p>
                            <p><i class="zmdi zmdi-phone"></i> 91- 79-29609664</p>
                            <p><i class="fa fa-whatsapp"></i> <a style="text-decorations:none; color:inherit;" href="https://wa.me/917227083338" target="_blank">91 - 7227 0 83338, 91-8320717854</a></p>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-5">
                <h4 class="irs-footer-heading text-center">Managed by <br><span class="title-orange"><strong>Shree Umiya
                            K.V.C. Education Trust</strong></span></h4>
                <div class="col-md-12">
                    <div class="irs-footer-contact">
                        <div class="irs-mailbox">
                            <p>Web : <a href="http://www.umiyakvcedutrust.org"
                                    target="_blank">www.umiyakvcedutrust.org</a></p>
                            <p><i class="zmdi zmdi-email"></i> E-mail: <a
                                    href="mailto:info@umiyakvcedutrust.org">info@umiyakvcedutrust.org</a></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
<!-- Footer end -->

<section class="irs-copyright-field">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <div class="irs-copyright">
                    <p>All rights reserve. Design by <a href="">Divya Solutions</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>