import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms'; 
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { ContactComponent } from './component/contact/contact.component';
import { HeaderComponent } from './Layout/header/header.component';
import { FooterComponent } from './Layout/footer/footer.component';
import { PresidentmessageComponent } from './component/presidentmessage/presidentmessage.component';
import { PrincipalmessageComponent } from './component/principalmessage/principalmessage.component';
import { VisionmissionComponent } from './component/visionmission/visionmission.component';
import { CourseComponent } from './component/course/course.component';
import { AdmissiontermComponent } from './component/admissionterm/admissionterm.component';
import { InfrastructureComponent } from './component/infrastructure/infrastructure.component';
import { JobopportunityComponent } from './component/jobopportunity/jobopportunity.component';
import { GalleryComponent } from './component/gallery/gallery.component';
import { TesturlComponent } from './component/testurl/testurl.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    PresidentmessageComponent,
    PrincipalmessageComponent,
    VisionmissionComponent,
    CourseComponent,
    AdmissiontermComponent,
    InfrastructureComponent,
    JobopportunityComponent,
    GalleryComponent,
    TesturlComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
