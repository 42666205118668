<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Job Opportunity</h2>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Divider end -->

<!-- courses end -->
<!-- Discription start -->
<section class="irs-discription-field">
    <div class="container">
        <div class="row">

            <div class="col-md-10 col-md-offset-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="irs-discription-col">
                                <p class="text-justify">With surplus private as well as government hospitals in India,
                                    there
                                    are various job
                                    opportunities for nursing graduates in both sectors. The rapid growth and
                                    privatization
                                    of the healthcare industry have enhanced employment in this sector. Each year the
                                    government hires 22,000 nurses. There is still a requirement of 4 lakh nurses in the
                                    country. Graduates can also apply for nursing posts at private healthcare centres.
                                    Following are some of the profiles that are available in this field:</p>
                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 irs-discription-col">
                                <ul>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Staff Nurse</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Nursing Service Administrators</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Assistant Nursing Superintendent</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Industrial Nurse</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Department Supervisor</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Nursing Supervisor or Ward Sister</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Nursing Superintendent</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Community Health Nurse (CHN)</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Director of Nursing</li>
                                  
                                </ul>
                            </div>
                            <div class="col-md-6 irs-discription-col">
                                <ul>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Military Nurse</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Deputy Nursing Superintendent</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Teacher of Nursing</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Certified nurse assistant</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Community health Officer</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Nurse Educator in Skilled Base Course</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>Nurses also develop carrier in international
                                        Register nurses(RN) in Respected forgiven countries</li>

                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Discription end  -->