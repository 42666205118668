<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Photo Gallery</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- Gallery start -->
<section class="irs-teachers-field">
    <div class="container">
        <div class="row animatedParent animateOnce">
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-250">
                    <img src="../../../assets/images/gallery/1.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-500">
                    <img src="../../../assets/images/gallery/2.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-750">
                    <img src="../../../assets/images/gallery/3.jpg" alt="umiya nursing college">

                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-1000">
                    <img src="../../../assets/images/gallery/4.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-1250">
                    <img src="../../../assets/images/gallery/5.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-1500">
                    <img src="../../../assets/images/gallery/6.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-1750">
                    <img src="../../../assets/images/gallery/7.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-2000">
                    <img src="../../../assets/images/gallery/8.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Oath Ceremony</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-2250">
                    <img src="../../../assets/images/gallery/9.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Lab Prectical</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-2500">
                    <img src="../../../assets/images/gallery/10.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Lab Prectical</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-2750">
                    <img src="../../../assets/images/gallery/11.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Lab Prectical</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-3000">
                    <img src="../../../assets/images/gallery/12.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Women's Day</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-3250">
                    <img src="../../../assets/images/gallery/13.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Women's Day</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-teachers-col animated fadeInUpShort slow delay-3500">
                    <img src="../../../assets/images/gallery/14.jpg" alt="umiya nursing college">
                    <div class="irs-teachers-name">
                        <p>Women's Day</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="irs-pagination text-center">
            <ul class="pagination">
                <li><a href="#">&lt;</a>
                </li>
                <li><a href="#">2</a>
                </li>
                <li><a href="#">3</a>
                </li>
                <li><a href="#">4</a>
                </li>
                <li><a href="#">&gt;</a>
                </li>
            </ul>
        </div> -->
    </div>
</section>
<!-- Gallery  end -->