export class InquiryModel {
    constructor(
    public UserName: string="",
    public Password: string="",
    public FromEmailId: string="inquiry@umiyakvcedutrust.org",
    public SenderName: string="Shree Umiya K.V.C. Nursing College",
    public ToEmail: string="shreeumiyakvcnursingcollege@gmail.com",
    public CcEmail: string="",
    public Subject: string="Inquiry",
    public Body: string="",
    public Host: string="relay-hosting.secureserver.net",
    public Port: number=25,
    public IsSSL: boolean=false
    ){}
}

export class ContactModel{
    constructor(
    public Name: string="",
    public Email: string="",
    public Mobile: string="",
    public Message: string=""
    ){}
}