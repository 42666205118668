import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs'
import { catchError, tap } from 'rxjs/operators'
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { InquiryModel, ContactModel } from './InquiryModel';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class InquiryService {

    inqModel = new InquiryModel();
    private apiUrl = "http://sendto.umiyakvcedutrust.org/mail/postmail/";
    httpClient: any;
    constructor(private http: HttpClient) {
    }

    // New BlockReg
    public SendInquiry(conModel: ContactModel): Observable<ContactModel> {

        //this.inqModel = new InquiryModel("","", "inquiry@umiyakvcedutrust.org", conModel.Name, conModel.Email);
        // this.inqModel.ToEmail = conModel.Email;
        this.inqModel.Body = "Inquiry Person :" + conModel.Name + "<br>Email:" + conModel.Email + "<br>Mobile : " + conModel.Mobile + "<br> Message :\n" + conModel.Message;
        let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        //headers = headers.append('Authorization', 'Bearer ' + `${this._globals.getToken()}`);
        return this.http.post<any>(this.apiUrl, this.inqModel, { headers: headers })
            .pipe(
                catchError(this.handleError)
            );
    }

    // New BlockReg
    public CallUrl(strUrl: string): Observable<any> {

        //this.inqModel = new InquiryModel("","", "inquiry@umiyakvcedutrust.org", conModel.Name, conModel.Email);
        // this.inqModel.ToEmail = conModel.Email;

        let headers = new HttpHeaders({ 'Content-Type': 'application/json',
        'Authorization': 'Basic ' + btoa('admin:1234') });
        //headers = headers.append('Authorization', 'Bearer ' + `${this._globals.getToken()}`);
        return this.http.get<any>(strUrl, { headers: headers })
            .pipe(
                catchError(this.handleError)
            );
    }
    public getDeviceData(strUrl: string): Observable<any> {
        const headers = new HttpHeaders().set(
          'Authorization',
          `Basic ${btoa(`admin:1234`)}`
        );
    
        return this.http.get(strUrl, { headers });
      }
    private handleError(error: HttpErrorResponse) {
        // console.log(error);
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError('Something bad happened; please try again later.');
    };
}
