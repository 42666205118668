<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Admission</h2>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Divider end -->

<!-- courses end -->
<!-- Discription start -->
<section class="irs-discription-field">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
                <h3 class="irs-sidebar-title">Terms & Condition</h3>
            </div>
        </div>
        <div class="row">

            <div class="col-md-12 col-md-offset-1">
                <div class="row">
                    <div class="col-md-12">
                        <div class="irs-discription-col">

                            <ul>
                                <h3><b>B.sc. Nursing</b></h3>
                                <li><i class="zmdi zmdi-dot-circle"></i>The minimum age for admission shall be 17 years
                                    on 31st December of the year in which admission is sought.</li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Minimum Education
                                    <ul class="col-md-offset-1">
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            A candidate should have passed in the subjects of PCB and English
                                            individually and must have obtained a minimum of 45% marks taken
                                            together in PCB at the qualifying examination (10+2)
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            Candidates are also eligible from state open school recognized by state
                                            government and national institute of open school (NIOS) Recognized by
                                            central government having science subject and English only.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            A candidate belonging to SC/ST or other backward classes, the marks obtained
                                            in PCB taken together in qualifying examination be 40% instead of 45% as
                                            stated above.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            English is a compulsory subject in 10+2 for being eligible for admission to
                                            B.Sc (N).
                                        </li>
                                    </ul>
                                </li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Candidate shall be medically fit.</li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Candidate shall be admitted once in a year.</li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Reservation policy
                                    <ul class="col-md-offset-1">
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            a. For disabled candidates: 3% disability Reservation to be considered with
                                            a disability of locomotor to the tune of 40% to 50% of the lower extremity
                                            and other eligibility criteria with regard to age and qualification will be
                                            same as prescribed for each nursing programme.<br>
                                            <b>Note</b> : A committee to be Formed consisting of medical officer
                                            authorized by
                                            medical board of state government and a nursing expert in the panel which
                                            may decide whether the candidate have the disability of locomotor to the
                                            tune of 40% to 50%.

                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            5% of total marks is relaxed for SC/ST candidates.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            Reservation SC/ST, OBC or any other shall be as per the state Govt. rules.
                                            Note:- reservation shall be applicable within the sanctioned number of the
                                            seats by INC and not above it.
                                        </li>

                                    </ul>
                                </li>
                            </ul>

                            <br>
                            <ul>
                                <h3><b>General Nursing & Midwifery (GNM)</b></h3>
                                <li><i class="zmdi zmdi-dot-circle"></i>Minimum education eligibility criteria for
                                    admission to GNM
                                    <ul class="col-md-offset-1">
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            10+2 with English and must have obtained a minimum of 40% at the qualifying
                                            examination and English individually from any recognized board. Candidates
                                            are also eligible from state open school recognized by state government and
                                            national institute of open school (NIOS) recognized by central government.
                                            However science is preferable.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            10+2 with English having 40% of marks in vocational stream- health care
                                            science from a recognized CBSE board/ state/ centre.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            <b>For foreign nationals</b><br>
                                            The entry qualification equivalency i.e., 12th standard will be obtained by
                                            association of Indian universities , new Delhi. Institution, state nursing
                                            council will be responsible to ensure that the qualification and eligibility
                                            will be equivalent to what has been prescribed as above.
                                        </li>
                                    </ul>
                                </li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Reservation
                                    <ul class="col-md-offset-1">
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            For disabled candidates: 3% disability reservation to be considered with a
                                            disability of locomotors to the tune of 40% to 50% of the lower extremity
                                            and other eligibility criteria with regard to age and qualification will be
                                            same as prescribed for each nursing programmed.<br>
                                            <b>Note :</b> A committee to be formed consisting of medical officer
                                            authorized by
                                            medical board of state government and a nursing expert in the panel which
                                            may decide whether the candidates have the disability of locomotor of 40% to
                                            50%.
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            5% of total marks is relaxed for SC/ST candidates
                                        </li>
                                        <li><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            Any other reservation as per the state govt.
                                        </li>

                                    </ul>
                                </li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Admission of students shall be once in a year.
                                </li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Students shall be medically fit.</li>
                                <li><i class="zmdi zmdi-dot-circle"></i>Minimum age for admission will be 17 years (as
                                    on 31st December of that year) The upper
                                    age limit is 35 yrs.FOR ANM/for LHV, there is no age bar.
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
<!-- Discription end  -->