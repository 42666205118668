import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InquiryService } from '../contact/inquiry.service';

@Component({
  selector: 'app-testurl',
  templateUrl: './testurl.component.html',
  styleUrls: ['./testurl.component.scss']
})
export class TesturlComponent implements OnInit {
  FormData: FormGroup;
  private _inqReg;
  
  constructor(private fb: FormBuilder, inqService: InquiryService) {
    this.initForms();
    this._inqReg = inqService;
  }

  ngOnInit(): void {
  }

  initForms() {
    this.FormData = this.fb.group({
      Message: ['', [Validators.required]]
    })
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.FormData.invalid) {
      alert("Something is wrong, Please fill correct information.");
      return;
    }
    else {

      this._inqReg.getDeviceData(this.FormData.value.Message)
        .subscribe(
          (response: any) => {
            console.log(response);
            alert('Inquiry submit sucess!');
          },
          (error) => {
            // console.log(error);
            // // this.snackbar.openSnackBar(error, 'Dismiss', 'red-snackbar');
            alert('Something wrong! Inquiry not submit.');
          });
      this.FormData.reset();
    }
  }
}
