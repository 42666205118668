<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Vision - Mission</h2>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- courses end -->
    <!-- Discription start -->
    <section class="irs-discription-field">
        <div class="container">
            <div class="row">
                <div class="col-md-10 col-md-offset-1 text-center">
                    <h3 class="irs-sidebar-title"><span>Vision</span></h3>
                    <p>
                        Our Vision is to prepare eminences and to be forerunners in the discovery, dissemination, and
                        application of nursing knowledge in order to reconcile and gratify the suffering humanity with
                        tender feeling and regard; and to be identified as a comprehensive leader in Nursing education and
                        research.</p>
                        <br><br>
                    <h3 class="irs-sidebar-title"><span>mission</span></h3>            
                </div>
            </div>
            <div class="row">
                
                <div class="col-md-12 col-md-offset-1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="irs-discription-col">
                                <ul>
                                    <li><i class="zmdi zmdi-dot-circle"></i>To promote professionalism by essence and value driven education with an unbound frame of mind.</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>To make learning an agitative practice that motivates learners to reach their full potential as lifelong learners.</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>To enlighten young professionals for leadership to respond to the health care needs of the public.</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>To support integration between research, education and practice by promoting interdisciplinary collaboration.</li>
                                    <li><i class="zmdi zmdi-dot-circle"></i>To be committed for furnishing nurse clinicians, scholars, and educators to meet the health needs of the people.</li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Discription end  -->
