<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Courses</h2>
                </div>
            </div>
            
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- courses start -->
<section class="irs-welcome-field">
    <div class="container">
        <div class="row">
            <div class="col-md-10 col-md-offset-1 text-center">
                <!-- <h3 class="irs-sidebar-title">Courses</h3> -->
                <br>
                <br>
                <div class="row animatedParent animateOnce">
                    <div class="col-md-6 col-sm-6">
                        <div class="irs-about-box animated fadeInLeftShort slow delay-250">
                            <div class="irs-about-icon">
                                <i class="icofont icofont-graduate-alt"></i>
                            </div>
                            <h4>Bachlour in Nursing (B.Sc Nursing)</h4>
                            <h3>Degree</h3>
                            <h6><strong>Duration</strong> 4 Years</h6>
                            <h6><strong>seats</strong> 60</h6>
                            
                            <a class="btn btn-default irs-btn-transparent" href="/admissionterm" role="button">Read More</a>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-6">
                        <div class="irs-about-box animated fadeInUpShort slow delay-500">
                            <div class="irs-about-icon">
                                <i class="icofont icofont-graduate-alt"></i>
                            </div>
                            <h4>General Nursing & Midwifery (G.N.M)</h4>
                            <h3>Diploma</h3>
                            <h6><strong>Duration</strong> 3 Years</h6>
                            <h6><strong>seats</strong> 60</h6>
                            <a class="btn btn-default irs-btn-transparent" href="/admissionterm" role="button">Read More</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- courses end -->

