<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Test</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Divider end -->
<!-- Contact start -->
<section class="irs-contact-field">
    <div class="container">
        <div class="row">
            <div class="col-md-8 col-sm-6">
                <div class="irs-contact-col">
                    <div class="row">
                        <form id="contact_form" [formGroup]="FormData" (ngSubmit)="onSubmit()">
                            <div class="col-md-12">
                                <textarea name="Message" formControlName="Message" class="form-control irs-textarea" rows="12" placeholder="Content*" required="required" data-error="Url content is required."></textarea>
                                <button class="btn btn-default irs-big-btn" type="submit">send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Contact end -->