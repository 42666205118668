import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { InquiryService } from './inquiry.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  FormData: FormGroup;
  private _inqReg;

  constructor(private fb: FormBuilder, inqService: InquiryService) {
    this.initForms();
    this._inqReg = inqService;
  }

  ngOnInit(): void {

  }
  initForms() {
    this.FormData = this.fb.group({
      Name: ['', [Validators.required]],
      Email: ['', [Validators.compose([Validators.required, Validators.email])]],
      Mobile: ['', [Validators.required]],
      Message: ['', [Validators.required]]
    })
  }

  onSubmit() {
    // stop here if form is invalid
    if (this.FormData.invalid) {
      alert("Something is wrong, Please fill correct information.");
      return;
    }
    else {

      this._inqReg.SendInquiry(this.FormData.value)
        .subscribe(
          (response: any) => {
            alert('Inquiry submit sucess!');
          },
          (error) => {
            // console.log(error);
            // // this.snackbar.openSnackBar(error, 'Dismiss', 'red-snackbar');
            alert('Something wrong! Inquiry not submit.');
          });
      this.FormData.reset();
    }
  }
}
