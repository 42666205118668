<!-- Divider start -->
<section class="irs-divider-field parallax irs-layer-black" data-stellar-background-ratio="0.3">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="irs-divider-col text-center">
                    <h2>Physical Facility</h2>
                </div>
            </div>

        </div>
    </div>
</section>
<!-- Divider end -->
<!-- Teachers Single Start -->
<section class="irs-teacher-single-field">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/infra/LECTUREROOM.jpeg" alt="lecture hall" style="width:300px;height:200px;">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Lecture Hall </strong> : 7 [100 seating capacity]
                        <p>All the Lecturer rooms are well equip with teaching learning facility like LCD, screen ,
                            green board, white board, podium and comfortable bench for the 60 students.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/infra/MULTIPURPOSEHALL.jpeg" alt="Hall" style="width:300px;height:200px;">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Multipurpose hall</strong>
                        <p>Multipurpose hall is utilised for hosting function of college education
                            conferences/workshops, CNES examinations etc. In hall have a proper stage with green room
                            facility. The hall is well ventilated with proper lighting facilities available. The proper
                            arrangements of place for the use of all kind of basic and advance audiovisual aids are
                            available in hall.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="images/teachers/1.jpg" alt="">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Examination Hall</strong>
                        <p>100 seated capacity examination hall available in our campus.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/infra/LIBRERY.jpeg" alt="Library" style="width:300px;height:200px;">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Library</strong>
                        <p>The library of our college has proper ventilation and proper lighting. The cabin of librarian
                            with intercom phone facility and with internet available. The comfortable seating
                            arrangement for students and teachers in our library. The separated reading room also there
                            in library. Book display racks at bulletin boards current books, magazines, journals,
                            newspapers and other literature available in our library.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="images/teachers/1.jpg" alt="">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Common Room for Female</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="images/teachers/1.jpg" alt="">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">A.V.AID Room</strong>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/infra/HOSTELBLOCK.jpeg" alt="Hostel" style="width:300px;height:200px;">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Hostel Block</strong>
                        <p>
                            Hostel in the same campus. There is a facility of recreation room. There is a facility of
                            sick room and Reading room. There is a facility of visitor room & Separate Parking. Drinking
                            water with aquagard, 24 Hrs Security & Mess facilities. Solar facility is available for hot
                            water. Meditation hall, indoor games facility, T.V. room & recreation room, Separate warden
                            room and warden office available in hostel block
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4 col-sm-4">
                <div class="irs-teacher-single-col">
                    <img src="../../../assets/images/infra/DININGHALL.jpeg" alt="Dining Hall" style="width:300px;height:200px;">
                </div>
            </div>
            <div class="col-md-8 col-sm-8">
                <div class="irs-teacher-single-col">
                    <div class="irs-teacher-biography">
                        <strong class="title-orange">Dining Hall</strong>
                        <p>
                            It is a well ventilated and with the capacity of 200 students at one time. Facility for
                            serving the hot food. with the use of hot food stand. Drinking Water facility available
                            with
                            cooler with aquagard (UV). Clean kitchen and store room available in dining area.
                        </p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
<!-- Blog start -->
<section class="irs-blog-field">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="../../../assets/images/infra/NURSINGFOUNDATIONLAB.jpeg" alt="Lab" style="width:370px;height:200px;">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Nursing Foundation Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="../../../assets/images/infra/COMMUNITYHEALTHNURSINGLAB.jpeg" alt="Community Lab" style="width:370px;height:200px;">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Community Health N Nursing (CHN) Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="../../../assets/images/infra/NUTRITIONLAB.gif" alt="Nutrition Lab" style="width:370px;height:200px;">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Nutrition Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="../../../assets/images/infra/METARNALANDCHILDHEALTHNURSINGLAB.jpeg" alt="Child Lab" style="width:370px;height:200px;">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Maternal and child health nursing Lab (MCH)</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="../../../assets/images/infra/COMPUTERLAB.jpeg" alt="Computer Lab" style="width:370px;height:200px;">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Computer Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="images/blog/1.jpg" alt="">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Pre-Clinical Sciences Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="irs-courses-col irs-blog-col">
                    <div class="irs-courses-img">
                        <img src="images/blog/1.jpg" alt="">
                    </div>
                    <div class="irs-info-text">
                        <ul class="clearfix">
                            <li>
                                <p><i class="icofont"></i> <a href="#">Advance Nursing Lab</a>
                                </p>
                            </li>  
                        </ul>
                    </div>
                </div>
            </div>
            
        </div>

    </div>
</section>
<!-- Blog end -->